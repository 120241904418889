import { useNavigate, useParams } from "react-router-dom";
import posters from "../slides";
import styled from "styled-components";

const SingleProduct = () => {
  const navigate = useNavigate();
  const { productId } = useParams();

  // Get product
  const singleProduct = posters.find(
    (poster) => poster.id === parseInt(productId)
  );

  const { image, alt, title, description, brightness, energyEfficiency, durability, colorTemperature, lifeSpan, environmentFriendly, varients, application, overall } = singleProduct;

  return (
    <Wrapper className="w-full h-full flex align-center justify-center font-sans">
        <div className="w-full h-full flex justify-center items-center flex-col px-[1%] py-[2%]">
          <div className="text-xl md:text-2xl">
            <h1>{title}</h1>
          </div>
          <div className="w-full flex items-center justify-center flex-col md:flex-row md:items-start mb-[1rem]">
            <div className="imgDiv w-[90%] flex justify-center items-center bg-gray-600 rounded-[10px]">
            <img src={image} alt={alt} className="object-contain" />
            </div>
            <div className='w-[90%] flex justify-start text-left flex-col details text-sm bg-gray-600 text-white md:text-base'>
              <p>{description}</p>
              <p><b>Brightness:</b> {brightness}</p>
              <p><b>Energy Efficiency:</b> {energyEfficiency}</p>
              <p><b>Durability:</b> {durability}</p>
              <p><b>Color Temperature:</b> {colorTemperature}</p>
              <p><b>Lifespan:</b> {lifeSpan}</p>
              <p><b>Environment Friendly:</b> {environmentFriendly}</p>
              <p><b>Varients:</b> {varients}</p>
              <p><b>Applications:</b> {application}</p>
              <p>{overall}</p>
            </div>
          </div>
          <div className="btn flex justify-center items-center md:w-[96%] md:justify-end">
            <button className="text-sm md:text-base" onClick={() => navigate("/products")}>Back</button>
          </div>
        </div>
    </Wrapper>
  );
};
export default SingleProduct;

const Wrapper = styled.section`

  .imgDiv {
    margin-bottom: 1rem;
    box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, 0.2);
  }

  img {
    max-width: 50%;
  }

  h1 {
    width: 100%;
    font-weight: bold;
    text-decoration: underline;
    text-align: center;
    text-transform: uppercase;
    margin: 10% auto;
  }

  .details {
    margin: auto 1rem;
    border-radius: 10px;
    border: none;
    box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, 0.2);
  }

  p {
    width: 90%;
    margin: 2% auto;
  }

  button {
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
    cursor: pointer;
    border: none;
  }
`;
