import React from 'react'
import datas from "../slides";

const Tagband = () => {
  return (
    <section className="tagband w-full max-w-[1140px] max-h-[35rem] h-[8rem] sm:h-[10rem] md:h-[10rem] flex justify-center items-center flex-col sm:flex-row mx-auto p-2 mt-[2rem] md:mt-[4rem] bg-purple-900">
       <div className='tagbandDiv hidden w-full sm:w-[40%] md:w-[30%]  h-full sm:flex justify-center items-center'>
        <img src={datas[0].image} alt="Led Bulb" className='w-full h-full object-contain' />
       </div>
       <div className='tagbandSecondDiv w-full sm:w-[60%] md:w-[70%] h-full flex justify-center items-center '>
        <h1 className='text-white font-sans font-bold text-2xl sm:text-3xl md:text-4xl italic'>Innovation is our future.</h1>
       </div>
    </section>
  )
}

export default Tagband
