import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import datas from "../slides";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";

const Products1 = (props) => {
  const { show } = props;

  const [posters] = useState(datas);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(datas.length);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const lastIndex = length - 1;

  useEffect(() => {
    setLength(datas.length);
  }, []);

  useEffect(() => {
    if (currentIndex < 0) {
      setCurrentIndex(lastIndex);
    }
    if (currentIndex > lastIndex) {
      setCurrentIndex(0);
    }
  }, [currentIndex, length, show, lastIndex]);

  useEffect(() => {
    let slider = setInterval(() => {
      setCurrentIndex(currentIndex + 1);
    }, 5000);
    return () => clearInterval(slider);
  }, [currentIndex]);

  const nBtn = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const pBtn = () => {
    setCurrentIndex(currentIndex - 1);
  };

  return (
    <section
      name="products"
      className="productsFirstDiv w-full h-full flex justify-center items-center flex-col m-auto mt-[2rem] md:mt-[4rem] md:mb-[4rem]"
    >
      <div className="productsTitle w-full m-[1rem] pl-[2rem] text-center">
        <h2 className="underline italic font-bold text-black  text-lg md:text-2xl lg:text-3xl text-center">
          Here's our Products
        </h2>
      </div>

      <div className="flex md:h-[28rem] sm:h-[22rem] max-w-[1140px] w-full justify-center items-center slider-container bg-purple-900">
        <div className="hidden md:flex productsDivPurple w-full h-full justify-center items-center flex-wrap">
          <div className="productsDivCardDiv w-[90%] h-[100%] sm:h-[80%] flex items-center bg-white rounded-lg m-4">
            <Carousel
              containerClass="carousel-container"
              itemClass="carousel-item-padding-40-px"
              responsive={responsive}
              swipeable={false}
              draggable={false}
              ssr={true} // means to render carousel on server-side.
              slidesToSlide={2}
              autoPlaySpeed={3000}
              infinite={true}
              className="w-full h-full flex justify-center items-center"
            >
              {posters.map((poster) => {
                return (
                  <Wrapper className="w-full h-full flex items-center justify-center font-sans">
                    <div className="w-full h-full flex content-center justify-center rounded-[3%] bg-[#707070]">
                      <Link to={`/products/${poster.id}`} className="w-full flex items-center justify-center flex-col rounded-md">
                        <div className="w-full flex items-center">
                          <figure>
                            <img
                              src={poster.image}
                              alt={poster.alt}
                              className="imgCarousel scale-[115%]"
                            />
                          </figure>
                        </div>

                        <h1>{poster.title}</h1>

                        <div className="flex justify-center items-center mb-1">
                          <p>{poster.description}</p>
                        </div>
                      </Link>
                    </div>
                  </Wrapper>
                );
              })}
            </Carousel>
          </div>
        </div>

        <div className="section flex md:hidden w-full h-[20rem] relative justify-center items-center my-2 p-1 bg-white">
          <div
            className="slider-icon leftArrow flex justify-center items-center bg-gray-700 text-white absolute top-[40%] left-2 opacity-[0.7] rounded-[50%] hover:scale-105 ease-in duration-200 p-1 cursor-pointer z-10"
            onClick={pBtn}
          >
            <AiOutlineArrowLeft size={28} />
          </div>
          <div className="section-center w-full h-full text-center relative flex justify-center items-center overflow-hidden rounded-lg">
            {posters.map((poster, posterIndex) => {
              let position = "nextSlide";

              if (posterIndex === currentIndex) {
                position = "activeSlide";
              }
              if (
                posterIndex === currentIndex - 1 ||
                (currentIndex === 0 && posterIndex === posters.length - 1)
              ) {
                position = "lastSlide";
              }

              return (
                <article className={position}>
                  <Link to={`/products/${poster.id}`} className="w-full h-full flex items-center justify-center flex-col rounded-md bg-gray-600">
                        <div className="w-full h-[60%] flex justify-center items-center">
                          <figure className="max-w-[55%] scale-[62%] h-full flex justify-center items-center">
                            <img
                              src={poster.image}
                              alt={poster.alt}
                              className="w-[100%] object-contain"
                            />
                          </figure>
                        </div>

                        <h1 className="text-lg text-white font-bold underline">{poster.title}</h1>

                        <div className="flex justify-center items-center p-2">
                          <p className="w-[90%] text-white text-xs">{poster.description}</p>
                        </div>
                      </Link>
                </article>
              );
            })}
          </div>
          <div
            className="slider-icon rightArrow bg-gray-700 text-white absolute top-[40%] right-2 opacity-[0.7] rounded-[50%] hover:scale-105 ease-in duration-200 p-1 cursor-pointer"
            onClick={nBtn}
          >
            <AiOutlineArrowRight size={28} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Products1;

const Wrapper = styled.section`

  .banner-image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  figure {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3%;
  }

  .imgCarousel {
    max-width: 70%;
    height: 150px;
    object-fit: contain;
  }

  h1 {
    width: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    text-decoration: underline;
    margin-top: 3%;
  }

  p {
    width: 80%;
    color: #fff;
    margin-top: 2%;
    padding: 2% 4%;                                                                                                                                         
  }
`;
