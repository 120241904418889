import { BrowserRouter, Routes, Route } from "react-router-dom";

import Hero              from './components/Hero';
import About             from './components/About';
import Products          from './components/Products';
import Contact           from './components/Contact';
// import BecomeDistributor from "./components/BecomeDistributor";
import SingleProduct     from './components/SingleProduct';
import ErrorPage         from "./components/ErrorPage";
// import datas from "../slides";

import MainLayout from "./components/MainLayout";

function App() {

  return (
    <>
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Hero />} />
          <Route path='about' element={<About />} />
          <Route path='contact' element={<Contact />} />
          <Route path='products' element={<Products />} />
          <Route path='/products/:productId' element={<SingleProduct />} />
          <Route path='*' element={<ErrorPage />} />
        </Route>
      </Routes>
     </BrowserRouter>
    </>    
  );
}

export default App;
