import { Outlet } from 'react-router-dom';

import Header from '../Stater/Header.jsx'
import Footer from '../Stater/Footer.jsx'
import Links  from '../Stater/Links.jsx'
import Navbar        from './Navbar';

import React from 'react'

const MainLayout = () => {
  return (
    <>
      <Header />
      <Navbar />
      <Outlet />
      <Links />
      <Footer />
    </>
  )
}

export default MainLayout