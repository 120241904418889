const poster = [
    {
        id: 1,
        image: require('./images/slides/Deeplight.png'),
    },
    {
        id: 2,
        image: require('./images/slides/COB.png'),
    },
    {
        id: 3,
        image: require('./images/slides/Led.png'),
    },
    {
        id: 4,
        image: require('./images/slides/Panel.png'),
    },
    {
        id: 5,
        image: require('./images/slides/Multicolor.png'),
    },
    {
        id: 6,
        image: require('./images/slides/Surface.png'),
    },
    {
        id: 7,
        image: require('./images/slides/Concealed.png'),
    },
    {
        id: 8,
        image: require('./images/slides/Spotlight.png'),
    },
];

export default poster;
