import React, { useEffect, useState } from 'react';

import About         from './About';
import Products      from './Products';
import Tagband       from './Tagband';
import Contact       from './Contact';

import data from '../data';


const Hero = () => {
    const [posters] = useState(data);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const lastIndex = posters.length - 1;

        if(index < 0) {
            setIndex(lastIndex);
        }
        if(index > lastIndex) {
            setIndex(0);
        }
    }, [index, posters]);

    useEffect(() => {
        let slider = setInterval(() => {
          setIndex(index + 1);
        }, 5000);
        return () => clearInterval(slider);
      }, [index]);

    return (
        <>
        <section name='home' className='section w-full h-[15rem] sm:h-[21rem] md:h-[26rem] mx-auto p-1 mt-[2rem] md:mt-[4rem]'>
            <div className='max-w-[1140px] w-full h-full relative flex justify-center items-center mx-auto overflow-hidden'>
                {posters.map((poster, posterIndex) => {
                    const {id, image, title} = poster;

                    let position = 'nextSlide';

                    if(posterIndex === index) {
                        position = 'activeSlide';
                    }
                    if(posterIndex === index - 1 || (index === 0 && posterIndex === posters.length - 1)) {
                        position = 'lastSlide';
                    }

                    return (
                        <article className={position} key={id} >
                            <img src={image} alt={title} className='max-w-[1140px] w-full h-full md:object-fill sm:object-cover poster-img' /> 
                        </article>
                    );
                })}
            </div>
        </section>
            <About />
            <Products show={4} />
            <Tagband />
            <Contact />
        </>
    );
};

export default Hero; 