import { Link } from "react-router-dom";
import React from "react";
import { MdLocationPin } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";

const Links1 = () => {
  return (
    <section className="linksDiv w-full h-full flex justify-center items-around flex-col m-auto bg-slate-800 mt-[2rem] sm:p-2 md:mt-[4rem] py-2 md:p-4">
      <div className="linksSecondDiv grid sm:grid-cols-2 gap-4 w-full h-full mx-auto sm:flex-row">

        <div className="linksThirdDiv w-full h-full sm:w-[100%] flex items-center justify-center flex-col ">
          <h2 className='w-full font-bold text-white text-lg md:text-xl lg:text-2xl text-center '>Quick Links</h2>
          <ul className="linksThirdDivUL w-full h-full flex items-center justify-between flex-col  p-2">
            <li className="cursor-pointer">
              <Link to="/" className='text-xs sm:text-sm md:text-lg text-gray-300 hover:text-white'>Home</Link>
            </li>
            <li className="cursor-pointer">
              <Link to="about" className='text-xs sm:text-sm md:text-lg text-gray-300 hover:text-white'>About</Link>
            </li>
            <li className="cursor-pointer">
              <Link to="products" className='text-xs sm:text-sm md:text-lg text-gray-300 hover:text-white'>Products</Link>
            </li>
            <li className="cursor-pointer">
              <Link to="contact" className='text-xs sm:text-sm md:text-lg text-gray-300 hover:text-white'>Feedback Form</Link>
            </li>
          </ul>
        </div>

        <div className='linksThirdDiv w-full h-full sm:w-[100%] flex items-center justify-center md:justify-between flex-col'>

          <h2 className='w-full h-full mb-1 text-white text-lg md:text-xl lg:text-2xl text-center'>Contact Us</h2>

          <div className='w-full h-full flex items-center justify-center border-2 border-black md:mb-5'>
          <div className="w-[15%] h-full flex items-center justify-center p-1  border-black border-r-2">
            <MdLocationPin size={40} className='text-gray-300' />
          </div>
          <div className='w-[85%] h-full flex items-center justify-center flex-col p-1'>
            <p className='text-xs sm:text-sm md:text-lg text-gray-300'>MARS MANUFACTURER</p>
            <p className='text-xs sm:text-sm md:text-lg text-gray-300 flex flex-wrap text-center'>Sapna Cinema Road, Ara - 802301</p>
            <p className='text-xs sm:text-sm md:text-lg text-gray-300'>Bihar, India</p>
          </div>
          </div>

          <div className='w-full h-full flex items-center justify-center border-2 border-black my-1'>
          <div className="w-[15%] h-full flex items-center justify-center p-2 border-black border-r-2">
            <BsFillTelephoneFill size={23} className='text-gray-300' />
          </div>
          <div className="w-[85%] h-full flex items-center justify-center flex-col">
            <p className="text-xs sm:text-sm md:text-lg  text-gray-300">+91 7766877359</p>
          </div>
        </div>

        <div className="w-full h-full md:hidden flex items-center justify-center border-2 border-black mt-1">
        <div className="w-[15%] h-full flex items-center justify-center p-2 border-black border-r-2">
          <AiOutlineClockCircle size={30} className=" text-gray-300" /></div>
          <div className="w-[85%] h-full flex items-center justify-center flex-col">
          <p className="text-xs sm:text-sm  text-gray-300">Monday - Saturday: 10AM - 7PM</p>
          </div>
        </div>
      </div>
        </div>
      </section>
  );
};

export default Links1;
