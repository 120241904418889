import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import photo from '../images/slides/ML.png';

const TopBar = () => {
  
  return (
    <section className="tagBar w-full h-full min-h-[40px] md:h-[60px] flex justify-between items-center bg-purple-900 px-1 sm:py-2">
      <div className="titleLogo flex items-center">
        <div className="logoImageDiv bg-transparent">
          <img src={photo} alt="Logo" className="logoImage w-12 md:w-20 h-full sm:mr-2 mr-1" />
        </div>
        <h1 className="titleName text-[120%] font-bold text-yellow-500 sm:text-2xl md:text-[1.6rem]">
          <Link to="/">MARS MANUFACTURER</Link>
        </h1>
      </div>
      <div className="flex">
        <div className="hidden md:flex items-center px-4">
          <AiOutlineClockCircle size={20} className="mr-2 text-white" />
          <p className="text-sm text-white">Monday - Saturday: 10AM - 7PM</p>
        </div>
      </div>
     
      <button className="emailName py-1 md:py-2 px-3 md:px-4 rounded-md border-0 hover:rotate-6 duration-200 ease-in">
        <a href="/" 
          className="font-xs md:font-semibold"
          onClick={() => window.location = 'mailto:help@marsmanufacturers.com'}  target="_blank" rel="noreferrer"
        >
          Email
        </a>
      </button>
    </section>
  );
};

export default TopBar;
