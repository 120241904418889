import React from "react";

import data from "../images/slides/flood.png";

import pdfFile from "../assets/Brochure.pdf";

const About = () => {
  return (
    <section name="about" className="about1 w-full max-h-[115rem] md:h-[27rem] flex justify-center items-center flex-col mx-auto px-1 mt-[2rem] md:mt-[4rem] md:mb-[4rem]">
      
      <div className="aboutDiv1 max-w-[1140px] grid sm:grid-cols-2 gap-4 w-full h-full mt-2 border border-gray-400 md:border-2 md:border-gray-400">
        <div className="aboutDiv2 w-full h-full flex justify-content items-content mx-auto">
          <img
            src={data} 
            alt="Flood Light"
            className="aboutDivPhoto w-[70%] sm:object-contain object-cover mx-auto"
          />
        </div>
        <div className="aboutInfo w-full h-full flex justify-center items-center flex-col mx-auto">
          <div className="aboutInfoDiv w-full h-full flex justify-center items-center flex-col mx-auto p-2">
            <h1 className="aboutInfoDivTitle font-bold text-black text-lg md:text-2xl lg:text-3xl text-center">
              Welcome To MARS MANUFACTURER
            </h1>
            <p className="aboutInfoDivParagraph text-sm md:text-lg text-center text-gray-700 mt-[1rem] mx-auto">
              In this constantly changing era, LED is the big breakthrough, when
              we talk about power saving. MARS MANUFACTURER is a venture of LED
              Products. We give emphasis on how to reduce power consumption of
              LED products. Our company has a goal to give quality products to
              our customers.
            </p>
          </div>
          <div className="aboutDivBtn mx-auto my-4">
            <form action={pdfFile} method="get" target="_blank" download>
              <button className="bg-[#2a6c36] rounded-lg text-sm md:text-lg text-white hover:bg-purple-800 border border-none" type="submit">
                Download Catalogue
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
