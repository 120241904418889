import React from "react";

import datas from "../slides";

const Contact = () => {
  return (
    <section name="contact" className="contactDiv w-full flex justify-center items-center mx-auto mt-[2rem] p-1 md:mt-[4rem] md:mb-[4rem]">
      <div className=" max-w-[1140px] w-full flex justify-center items-center flex-col p-2 border border-gray-400 md:border-2 md:border-gray-400">
        <div className="contactDivTitle w-full text-center">
          <h1 className="underline italic font-bold text-black text-lg md:text-2xl lg:text-3xl text-center">Customer Feedback Form</h1>
        </div>
        <div className="contactSecondDiv grid sm:grid-cols-2 gap-4 w-full h-full mx-auto mt-[1rem] md:mt-[2rem]">
        <div className="hidden sm:flex sm:w-full h-full  justify-content items-content mx-auto">
          <img
            src={datas[7].image}
            alt="Multicolor Pro"
            className="w-full sm:w-[70%] sm:object-contain h-full mx-auto"
          />
        </div>
          <div className="contactThirdDiv w-full h-full flex justify-center items-center flex-col mx-auto py-2">
            <form
              method="POST"
              action="https://getform.io/f/bd6a1c9a-82ab-4402-a03f-38feedd1c60b"
              className="flex flex-col w-full"
            >
              <input
                className="bg-[#ccd6f6] pl-2 p-1 rounded"
                type="text"
                placeholder="Name"
                name="name"
              />
              <input
                className="my-2 pl-2 p-1 bg-[#ccd6f6] rounded"
                type="email"
                placeholder="Email"
                name="email"
              />
              <textarea
                className="bg-[#ccd6f6] pl-2 p-1 rounded"
                name="message"
                rows="5"
                placeholder="Write Your Message Here..."
              ></textarea>
              <div className="w-full flex justify-start md:justify-center">
              <button className="contactThirdDivBtn text-sm md:text-[100%] lg:text-[105%] border-none px-4 py-2 mt-2 rounded-lg">
                Let's Connect
              </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
