import { Link } from 'react-router-dom'
import React from 'react'

const Footer = () => {
  return (
    <section name="footer" className="footer w-full flex justify-center items-center flex-col border-2 border-black bg-black p-3">
        <Link to='/' className="text-xs sm:text-sm md:text-lg text-gray-300 cursor-pointer hover:text-white">All rights are reserved by Mars Manufacturer @2021-2023.</Link>
    </section>
  )
}

export default Footer
