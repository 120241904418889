import React from 'react'

import data from "../images/slides/Poster404.png";

const ErrorPage = () => {
  return (
    <section name="errorpage" className="about1 w-full max-h-[115rem] md:h-[27rem] flex justify-center items-center flex-col mx-auto px-1 mt-[2rem] md:mt-[4rem] md:mb-[4rem]">
      <div className="aboutDiv1 max-w-[1140px] w-full h-full mt-2">
        <div className="aboutDiv2 w-full h-full flex justify-content items-content mx-auto">
          <img
            src={data} 
            alt="Flood Light"
            className="aboutDivPhoto w-full h-full object-cover mx-auto"
          />
          </div>
        </div>
    </section>
  )
}

export default ErrorPage;