import { Link } from 'react-router-dom';
import React, { useState } from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaBars,
  FaTimes,
} from "react-icons/fa";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <>
    <section className="navbarHeight w-full flex justify-between items-center text-white px-2 py-2 bg-black/90">
      <ul className="hidden md:flex px-4">
        <li className="cursor-pointer">
          <Link to='/' className="text-gray-300 hover:text-white listName">
            HOME
          </Link>
        </li>
        <li className="cursor-pointer">
          <Link to="about" className="text-gray-300 hover:text-white listName">
            ABOUT
          </Link>
        </li>
        <li className="cursor-pointer">
          <Link to="products" className="text-gray-300 hover:text-white listName">
            PRODUCT
          </Link>
        </li>
        <li className="cursor-pointer">
          <Link to="contact" className="text-gray-300 hover:text-white listName">
            CONTACT
          </Link>
        </li>
        {/* <li className="cursor-pointer">
          <Link to="becomeDistributor" className="text-gray-300 hover:text-white listName">
            BECOME A DISTRIBUTOR
          </Link> */}
        {/* </li> */}
      </ul>
      <div className="flex justify-between">
        <a href="https://www.facebook.com/MarsManufacturers1/" rel="nofollow noreferrer" target="_blank">
          <FaFacebookF className="mx-4 cursor-pointer md:scale-[1.2] md:hover:scale-[1.3] hover:scale-[1.1] duration-200" />
        </a>
        <a href="https://twitter.com/MarsManufactur4" rel="nofollow noreferrer" target="_blank">
          <FaTwitter className="mx-4 cursor-pointer md:scale-[1.2] md:hover:scale-[1.3] hover:scale-[1.1] duration-200" />
        </a>
        <a href="https://www.instagram.com/marsmanufacturer/" rel="nofollow noreferrer" target="_blank">
          <FaInstagram
            className="mx-4 cursor-pointer md:scale-[1.2] md:hover:scale-[1.3] hover:scale-[1.1] duration-200"
          />
        </a>
      </div>

      {/* Hamburger Icon */}
      <div onClick={handleNav} className="md:hidden z-40">
        {!nav ? (
          <FaBars size={20} className="mr-4 cursor-pointer" />
        ) : (
          <FaTimes size={20} className="mr-4 cursor-pointer" />
        )}
      </div>

      {/* Mobile Menu */}
      <div
        onClick={handleNav}
        className={
          nav
            ? "overflow-y-hidden md:hidden ease-in duration-200 absolute text-gray-300 left-0 top-0 w-full h-screen bg-black/90 px-4 py-7 flex flex-col z-30"
            : "absolute top-0 h-screen left-[-100%] ease-in duration-500 z-30"
        }
      >
        <ul className="flex items-center flex-col mt-12">
          <li className="hover:text-white p-3 cursor-pointer">
            <Link to="/" className="listName text-xl">
              Home
            </Link>
          </li>
          <li className="hover:text-white p-3 cursor-pointer">
            <Link to="about" className="listName text-xl">
              About
            </Link>
          </li>
          <li className="hover:text-white p-3 cursor-pointer">
            <Link to="products" className="listName text-xl">
              Product
            </Link>
          </li>
          <li className="hover:text-white p-3 cursor-pointer">
            <Link to="contact" className="listName text-xl">
              Contact
            </Link>
          </li>
            {/* <Link to="becomeDistributor" className="text-gray-300 hover:text-white listName">
              Become a distributor
            </Link> */}
        </ul>
      </div>
    </section>
    </>
  );
};

export default Navbar;
