const poster = [
    {
        id: 1,
        image: require('./images/slides/bulb.png'),
        alt: 'LED BULB',
        title: 'LED BULB',
        description: 'LED bulbs provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED bulb can produce a great quality of light.',
        energyEfficiency: 'MARS LED bulbs are known for their energy efficiency, and it consumes significantly less electricity compared to traditional bulbs. This can result in lower energy bills and reduced environmental impact.',
        durability: 'LED bulbs are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED bulbs are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED bulbs come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED bulb can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED bulbs have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent bulb replacements.',
        environmentFriendly: 'MARS LED bulbs do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '9W 12W 15W',
        application: 'LED Bulbs are used in homes, shops, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED bulb offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 2,
        image: require('./images/slides/panel1.png'),
        alt: 'PANEL LIGHT',
        title: 'PANEL LIGHT',
        description: 'LED Panel Light provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED Panel Light can produce a great quality of light.',
        energyEfficiency: 'MARS LED Panel Light are known for their energy efficiency, and it consumes significantly less electricity compared to traditional bulbs. This can result in lower energy bills and reduced environmental impact.',
        durability: 'LED Panel Light are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED bulbs are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED bulbs come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Panel Light can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Panel Light have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Panel Light replacements.',
        environmentFriendly: 'MARS LED Panel Light do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '8W 12W 15W 20W',
        application: 'LED Panel Lights are used in offices, shops, malls, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED Panel Light offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 3,
        image: require('./images/slides/tubelight.png'),
        alt: 'TUBELIGHT',
        title: 'TUBELIGHT',
        description: 'LED Tubelight provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED Tubelight can produce a great quality of light.',
        energyEfficiency: 'MARS LED Tubelights are known for their energy efficiency, and it consumes significantly less electricity compared to traditional Tubelights. This can result in lower energy bills and reduced environmental impact.',
        durability: 'LED Tubelights are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED Tubelights are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED Tubelights come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Tubelight can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Tubelights have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Tubelight replacements.',
        environmentFriendly: 'MARS LED Tubelights do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: 'Color',
        application: 'LED Tubelights are used in homes, shops, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED Tubelight offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 4,
        image: require('./images/slides/flood.png'),
        alt: 'FLOOD LIGHT',
        title: 'FLOOD LIGHT',
        description: 'LED Flood Lights provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED Flood Light can produce a great quality of light.',
        energyEfficiency: 'MARS LED Flood Lights are known for their energy efficiency, and it consumes significantly less electricity compared to traditional Flood Lights. This can result in lower energy bills and reduced environmental impact.',
        durability: 'LED Flood Lights are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED Flood Lights are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED Flood Lights come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Flood Light can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Flood Lights have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Flood Light replacements.',
        environmentFriendly: 'MARS LED Flood Lights do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '50W 100W 150W 200W',
        application: 'LED Flood Lights are used in fields, sports complexes, parks, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED Flood Light offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 5,
        image: require('./images/slides/lamp.png'),
        alt: 'LED LAMP',
        title: 'LED LAMP',
        description: 'LED Lamps provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED Lamp can produce a great quality of light.',
        energyEfficiency: 'MARS LED Lamps are known for their energy efficiency, and it consumes significantly less electricity compared to traditional Lamps. This can result in lower energy bills and reduced environmental impact.',
        durability: 'LED Lamps are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED Lamps are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED Lamps come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Lamp can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Lamps have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Lamp replacements.',
        environmentFriendly: 'MARS LED Lamps do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '30W 50W',
        application: 'LED Lamps are used in homes, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED Lamp offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 6,
        image: require('./images/slides/concealed1.png'),
        alt: 'CONCEALED LIGHT',
        title: 'CONCEALED LIGHT',
        description: 'LED Concealed Lights provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED Concealed Light can produce a great quality of light.',
        energyEfficiency: 'MARS LED Concealed Lights are known for their energy efficiency, and it consumes significantly less electricity compared to traditional bulbs. This can result in lower energy bills and reduced environmental impact.',
        durability: 'LED Concealed Lights are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED Concealed Lights are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED Concealed Lights come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Concealed Light can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Concealed Lights have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Concealed Light replacements.',
        environmentFriendly: 'MARS LED Concealed Lights do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '6W 9W',
        application: 'LED Concealed Lights are used in shops, malls, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED Concealed Light offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 7,
        image: require('./images/slides/street.png'),
        alt: 'STREET LIGHT',
        title: 'STREET LIGHT',
        description: 'LED Street Lights provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED Street Light can produce a great quality of light.',
        energyEfficiency: 'MARS LED Street Lights are known for their energy efficiency, and it consumes significantly less electricity compared to traditional Street Lights. This can result in lower energy bills and reduced environmental impact.',
        durability: 'LED Street Lights are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED Street Lights are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED Street Lights come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Street Light can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Street Lights have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Street Light replacements.',
        environmentFriendly: 'MARS LED Street Lights do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '50W 100W 200W',
        application: 'LED Street Lights are used in fiels, roads, sports complexes, parks, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED Street Light offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 8,
        image: require('./images/slides/multicolor1.png'),
        alt: 'MULTICOLOR PRO',
        title: 'MULTICOLOR PRO',
        description: 'LED Multicolor Pro provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'Brightness: MARS LED Multicolor Pro can produce a great quality of light.',
        energyEfficiency: 'MARS LED Multicolor Pro are known for their energy efficiency, and it consumes significantly less electricity compared to traditional Multicolor Pro. This can result in lower energy bills and reduced environmental impact.',
        durability: 'LED Multicolor Pro are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED Multicolor Pro are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED Multicolor Pro come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Multicolor Pro can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Multicolor Pro have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Multicolor Pro replacements.',
        environmentFriendly: 'MARS LED Multicolor Pro do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '6W',
        application: 'LED Multicolor Pro are used in homes, offices, restaurants, hotels, rooms, shops, etc.',
        overall: 'Overall, MARS LED Multicolor Pro offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 9,
        image: require('./images/slides/spot.png'),
        alt: 'SPOT LIGHT',
        title: 'SPOT LIGHT',
        description: 'LED Spot Lights provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED Spot Light can produce a great quality of light.',
        energyEfficiency: 'MARS LED Spot Lights are known for their energy efficiency, and it consumes significantly less electricity compared to traditional Spot Lights. This can result in lower energy bills and reduced environmental impact.',
        durability: 'LED Spot Lights are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED Spot Lights are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED Spot Lights come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Spot Light can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Spot Lights have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Spot Light replacements.',
        environmentFriendly: 'MARS LED Spot Lights do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '1W 7W',
        application: 'LED Spot Lightss are used in malls, shops, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED Spot Light offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 10,
        image: require('./images/slides/deeplight1.png'),
        alt: 'DEEPLIGHT',
        title: 'DEEPLIGHT',
        description: 'LED Deeplights provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED Deeplight can produce a great quality of light.',
        energyEfficiency: 'MARS LED Deeplights are known for their energy efficiency, and it consumes significantly less electricity compared to traditional bulbs. This can result in lower energy bills and reduced environmental impact.',
        durability: 'LED Deeplights are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED Deeplights are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED Deeplights come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Deeplight can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Deeplights have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Deeplight replacements.',
        environmentFriendly: 'MARS LED Deeplights do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '3W',
        application: 'LED Deeplights are used in homes, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED Deeplight offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 11,
        image: require('./images/slides/tbulb.png'),
        alt: 'T-BULB',
        title: 'T-BULB',
        description: 'LED T-Bulbs provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED T-Bulb can produce a great quality of light.',
        energyEfficiency: 'MARS LED T-Bulbs are known for their energy efficiency.',
        durability: 'LED T-Bulbs are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED T-Bulbs are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED T-Bulbs come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED T-Bulb can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED T-Bulbs have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent T-Bulb replacements.',
        environmentFriendly: 'MARS LED T-Bulbs do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '10W',
        application: 'LED T-Bulbs are used in homes, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED T-Bulb offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 12,
        image: require('./images/slides/surface1.png'),
        alt: 'SURFACE LIGHT',
        title: 'SURFACE LIGHT',
        description: 'LED Surface Lights provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED Surface Light can produce a great quality of light.',
        energyEfficiency: 'MARS LED Surface Lights are known for their energy efficiency.',
        durability: 'LED Surface Lights are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED Surface Lights are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED Surface Lights come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Surface Light can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Surface Lights have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Surface Light replacements.',
        environmentFriendly: 'MARS LED Surface Lights do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: '8W, 12W, 15W',
        application: 'LED Surface Lights are used in shops, malls, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED Surface Light offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 13,
        image: require('./images/slides/zero.png'),
        alt: 'DECO LED LAMP',
        title: 'DECO LED LAMP',
        description: 'LED Deco Led Lamps provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED Deco Led Lamp can produce a great quality of light.',
        energyEfficiency: 'MARS LED Deco Led Lamps are known for their energy efficiency.',
        durability: 'LED Deco Led Lamps are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED Deco Led Lamps are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED Deco Led Lamps come in different color temperatures, ranging from warm white to cool white. The specific color temperature of aN LED Surface Light can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED Deco Led Lamps have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent Surface Light replacements.',
        environmentFriendly: 'MARS LED Deco Led Lamps do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: 'Zero Watt',
        application: 'LED Deco Led Lamps are used in homes, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED Deco Led Lamp offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
    {
        id: 13,
        image: require('./images/slides/COB1.png'),
        alt: 'COB',
        title: 'COB',
        description: 'LED COBs provide energy-efficient, long-lasting, and versatile lighting solutions suitable for various applications, including residential, commercial, and industrial settings.',
        brightness: 'MARS LED COB can produce a great quality of light.',
        energyEfficiency: 'MARS LED COBs are known for their energy efficiency.',
        durability: 'LED COBs are constructed with solid-state components, making them more robust and resistant to shock and vibrations. LED COBs are ideal for environments with rough conditions or where frequent handling is necessary.',
        colorTemperature: 'MARS LED COBs come in different color temperatures, ranging from warm white to cool white. The specific color temperature of COB can vary, but it is often in the range of 2700K to 5000K, providing a range of lighting options for different preferences and applications.',
        lifeSpan: 'MARS LED COBs have an impressive lifespan, and it can last up to 25,000 to 50,000 hours or even longer. This longevity eliminates the need for frequent COB replacements.',
        environmentFriendly: 'MARS LED COBs do not contain hazardous materials like mercury, making them safer for the environment. Additionally, their energy efficiency helps reduce greenhouse gas emissions associated with electricity generation.',
        varients: 'COB',
        application: 'LED COBs are used in homes, offices, restaurants, hotels, etc.',
        overall: 'Overall, MARS LED COB offers energy efficiency, long lifespan, and a range of lighting options, making it a popular choice for residential and commercial lighting applications.',
    },
];

export default poster;
